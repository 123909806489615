export const formatLongString = (str, noOfChars, appendStr) => {
    if (str.length <= noOfChars) {
        return str;
    }
    return str.substring(0, noOfChars).concat(appendStr);
};

export const getHtmlString = (str) => {
    var temporalDivElement = document.createElement("div");
    // Set the HTML content with the providen
    temporalDivElement.innerHTML = str;
    // Retrieve the text property of the element (cross-browser support)
    return temporalDivElement.textContent || temporalDivElement.innerText || "";
};