import React from 'react';
import styled from 'styled-components';
import FacebookIcon from '../assets/FacebookIcon';
import InstagramIcon from '../assets/InstagramIcon';

const FooterWrapper = styled.div`
    width: 100%;
    height: auto;
    background-color: ${props => props.theme.mainColor};
`;

const PaddingWrapper = styled.div`
    padding: 16px 80px;
    justify-content: space-between;
    display: flex;
    flex-wrap: wrap;
`;

const Navigation = styled.div`
    flex: 1 0 33%;
    text-align: center;
    color: #ffffff;
    font-weight: 300;
    line-height: 24px;

    > h4 {
        font-size: 24px;
    }

    > p {
        margin: 4px 0;
        font-size: 16px;
    }

    @media (max-width: 800px) {
        flex: 1 0 100%;
    }
`;

const SocialMediaWrapper = styled.div`
    display: flex;
    width: 40%;
    margin: 0 auto;
    justify-content: space-around;
`;

const Footer = () => (
    <FooterWrapper>
        <PaddingWrapper>
            <Navigation>
                <h4>Um okkur</h4>
                <p>Kringlan 4-12, 3.hæð (Sjúkraþjálfun Íslands)</p>
                <p>103 Reykjavík</p>
                <p>
                    <a style={{ color: 'white' }} href={'mailto:4health@4health.is'}>
                        4health@4health.is
                    </a>
                </p>
            </Navigation>
            <Navigation>
                <h4>Opnunartímar</h4>
                <p>Alla virka daga: 8:00 - 16:00</p>
            </Navigation>
            <Navigation>
                <h4>Samfélagsmiðlar</h4>
                <SocialMediaWrapper>
                    <a
                        href={'https://instagram.com/4health.is?igshid=MzRlODBiNWFlZA=='}
                        target={'_blank'}
                    >
                        <InstagramIcon />
                    </a>
                    <a
                        href={'https://www.facebook.com/4-Health-622738261528791/'}
                        target={'_blank'}
                    >
                        <FacebookIcon />
                    </a>
                </SocialMediaWrapper>
            </Navigation>
        </PaddingWrapper>
    </FooterWrapper>
);

export default Footer;
