import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { darken } from 'polished';
import { Slide } from 'react-slideshow-image';
import Logo from '../assets/Logo';

const Wrapper = styled.div`
    display: flex;
    justify-content: space-between;
    height: 500px;
    @media (max-width: 1000px) {
        height: auto;
    }
`;

const InfoWrapper = styled.div`
    flex: 1 0 50%;
`;

const PictureWrapper = styled.div`
    flex: 1 0 50%;
    background-color: ${props => props.theme.mainColor};
    padding-bottom: 50px;
    @media (max-width: 800px) {
        display: none;
    }
`;

const Header = styled.div`
    font-size: 32px;
    font-weight: bold;
    margin: 32px 60px;
    justify-content: flex-start;
    @media (max-width: 450px) {
        margin: 32px;
    }
`;

const InfoText = styled.div`
    font-size: 16px;
    margin: 32px 60px;
    line-height: 28px;
    padding-right: 120px;

    @media (max-width: 1000px) {
        padding-right: 16px;
    }
    @media (max-width: 800px) {
        padding-right: 0;
    }
    @media (max-width: 450px) {
        margin: 32px;
    }
`;

const StyledButton = styled(Link)`
    border-radius: 24px;
    background-color: ${props => props.theme.secondaryColor};
    color: white;
    padding: 10px 60px;
    margin-left: 60px;
    margin-top: 32px;
    text-decoration: none;

    &:hover {
        background-color: ${props => darken(0.15, props.theme.secondaryColor)};
    }

    @media (max-width: 450px) {
        width: 100%;
    }
`;

const MainPictureWrapper = styled.div`
    margin-left: -100px;
    margin-top: 64px;
    width: 500px;

    .slide-container {
        width: 30%;
        margin: auto;
    }

    .each-slide > div {
        display: flex;
        align-items: center;
        justify-content: center;
        background-size: cover;
        height: 300px;
    }

    .each-slide span {
        padding: 20px;
        font-size: 20px;
        background: #efefef;
        text-align: center;
    }

    .react-slideshow-container + div.indicators {
        display: none;
    }

    @media (max-width: 1000px) {
        margin: 0 auto;
        width: 300px;
    }
`;

const properties = {
    duration: 7000,
    transitionDuration: 1000,
    infinite: true,
    indicators: true,
    arrows: true,
};

class HomePageComponent1 extends React.Component {
    render() {
        const { pictures, description } = this.props;
        return (
            <Wrapper>
                <InfoWrapper>
                    <Header>4Health</Header>
                    <InfoText>
                        <span dangerouslySetInnerHTML={{ __html: description }} />
                    </InfoText>
                    <StyledButton to={'/products'}>Skoða vörur</StyledButton>
                </InfoWrapper>
                <PictureWrapper>
                    <MainPictureWrapper>
                        {pictures ? (
                            <Slide {...properties}>
                                {pictures &&
                                    pictures.map((pic, ind) => (
                                        <div className="each-slide" key={ind}>
                                            <div
                                                style={{
                                                    backgroundImage: `url(${pic.photoURL})`,
                                                    backgroundSize: 'contain',
                                                    backgroundRepeat: 'no-repeat',
                                                    backgroundPosition: 'center',
                                                }}
                                            />
                                        </div>
                                    ))}
                            </Slide>
                        ) : (
                            <Logo width={521} height={360} />
                        )}
                    </MainPictureWrapper>
                </PictureWrapper>
            </Wrapper>
        );
    }
}

export default HomePageComponent1;
