import { ContentState, convertFromHTML, convertToRaw, EditorState } from 'draft-js';
import * as draftToHtml from 'draftjs-to-html';
import { debounce } from 'lodash-es';
import React from 'react';
import styled from 'styled-components';
import { Editor } from 'react-draft-wysiwyg';
// tslint:disable-next-line
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const EditorWrapper = styled.div`
    border: 1px solid gray;
    padding: 12px;
    border-radius: 5px;
    background: #fff;

    &.error {
        border: 1px solid red;
    }

    .rdw-editor-main {
        min-height: 200px;
    }

    .rdw-link-modal-target-option {
        display: none;
    }

    .DraftEditor-editorContainer {
        z-index: auto;
    }

    .rdw-editor-toolbar {
        margin-left: -12px;
        margin-right: -12px;
        margin-top: -12px;
        border: none;
        border-bottom: 0;
        border-radius: 5px;
        padding: 12px 8px 0 8px;

        a {
            color: black;
        }

        .rdw-option-wrapper {
            border-color: #fff;
            height: 30px;
            padding: 10px;
            border-radius: 3px;

            &:hover {
                box-shadow: none;
                border-color: gray;
            }

            &[aria-selected='true'] {
                box-shadow: none;
                border-color: blue;
                background-color: gray;
            }
        }
    }
`;

const DefaultToolbarOptions = {
    options: ['blockType', 'fontSize', 'inline', 'list', 'textAlign', 'link', 'image', 'history'],
    inline: {
        options: ['bold', 'italic', 'underline'],
    },
    list: {
        options: ['unordered', 'ordered'],
    },
};

/**
 * WYSIWYG Textarea Editor
 */
class WysiwygEditor extends React.Component {
    constructor(props) {
        super(props);
        const blocksFromHTML = convertFromHTML(this.props.value || '');

        let contentState = ContentState.createFromText('');
        if (blocksFromHTML.contentBlocks && blocksFromHTML.contentBlocks.length > 0) {
            contentState = ContentState.createFromBlockArray(
                blocksFromHTML.contentBlocks,
                blocksFromHTML.entityMap
            );
        }
        this.state = {
            editorState: EditorState.createWithContent(contentState),
        };

        this.onChange = debounce(this.onChange, 500);
    }

    onEditorStateChange = editorState => {
        this.setState(
            {
                editorState,
            },
            this.onChange
        );
    };

    getCurrentHtmlValue = () => {
        const currentContent = this.state.editorState.getCurrentContent();
        if (!currentContent.hasText()) {
            return '';
        }
        return draftToHtml(convertToRaw(currentContent));
    };

    onChange = () => {
        if (this.props.onChange) {
            this.props.onChange(this.getCurrentHtmlValue());
        }
    };

    handleBlur = () => {
        if (this.props.onBlur) {
            this.props.onBlur(this.getCurrentHtmlValue());
        }
    };

    render() {
        const { editorState } = this.state;
        const { error, spellcheck, disabled } = this.props;

        return (
            <EditorWrapper disabled={disabled} className={error ? 'error' : ''}>
                <Editor
                    editorState={editorState}
                    wrapperClassName="demo-wrapper"
                    editorClassName="demo-editor"
                    onBlur={this.handleBlur}
                    onEditorStateChange={this.onEditorStateChange}
                    spellCheck={spellcheck || true}
                    toolbar={DefaultToolbarOptions}
                    readOnly={disabled}
                />
            </EditorWrapper>
        );
    }
}
export default WysiwygEditor;
