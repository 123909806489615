import { Component } from 'react';
import React from 'react';
import * as auth from '../../firebase/auth';
import styled from 'styled-components';

const INITIAL_STATE = {
    email: '',
    password: '',
    error: null,
};

const StyledButton = styled.button`
    margin-top: 16px;
    width: 97%;
    border-radius: 12px;
    background-color: ${props => props.theme.mainColor};
    height: 24px;
    color: white;
`;

class SignInForm extends Component {
    constructor(props) {
        super(props);

        this.state = { ...INITIAL_STATE };
    }

    updateByPropertyName = (propertyName, value) => () => ({
        [propertyName]: value,
    });

    onSubmit = event => {
        const { email, password } = this.state;
        auth.doSignInWithEmailAndPassword(email, password)
            .then(() => {
                this.setState(() => ({ ...INITIAL_STATE }));
            })
            .catch(error => {
                this.setState(this.updateByPropertyName('error', error));
            });

        event.preventDefault();
    };

    render() {
        const { email, password, error } = this.state;

        const isInvalid = password === '' || email === '';

        return (
            <form onSubmit={this.onSubmit}>
                <input
                    value={email}
                    onChange={event =>
                        this.setState(this.updateByPropertyName('email', event.target.value))
                    }
                    type="text"
                    placeholder="Email Address"
                />
                <input
                    value={password}
                    onChange={event =>
                        this.setState(this.updateByPropertyName('password', event.target.value))
                    }
                    type="password"
                    placeholder="Password"
                />
                <StyledButton disabled={isInvalid} type="submit">
                    Sign In
                </StyledButton>

                {error && <p>{error.message}</p>}
            </form>
        );
    }
}

export default SignInForm;
