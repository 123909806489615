import React, { Component } from 'react';
import HomePageComponent1 from '../components/HomePageComponent1';
import Header from '../components/Header';
import Footer from '../components/Footer';
import HomePageComponent2 from '../components/HomePageComponent2';
import { getCompanies, getDescription, getFeaturedProducts, getPictures } from '../firebase/db';
import HomePageComponent3 from '../components/HomePageComponent3';
import { HomeWrapper } from '../themes/basicTheme';
import LoadingIcon from '../assets/LoadingIcon';
import { LoadingWrapper } from './ProductsPage';

class HomePage extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            products: [],
            companies: [],
            pictureURLs: [],
            loading: true,
            description: '',
        };
    }

    componentDidMount() {
        this._isMounted = true;
        getCompanies().then(comps => {
            const companies = comps.val();
            if (this._isMounted) {
                this.setState({
                    companies,
                });
            }
        });
        getFeaturedProducts().then(products => {
            let productArray = [];
            products.forEach(val => {
                if (val.val().featured && productArray.length < 3) {
                    productArray.push(val.val());
                }
            });
            getPictures().then(pictures => {
                let pics = [];
                pictures.forEach(childPics => {
                    pics.push(childPics.val());
                });
                getDescription().then(description => {
                    if (this._isMounted) {
                        this.setState({
                            pictureURLs: pics,
                            products: productArray,
                            loading: false,
                            description: description.val(),
                        });
                    }
                });
            });
        });

    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        const { products, companies, pictureURLs, loading, description } = this.state;
        return (
            <HomeWrapper>
                <Header />
                {loading ? (
                    <LoadingWrapper>
                        <LoadingIcon />
                    </LoadingWrapper>
                ) : (
                    <>
                        <HomePageComponent1 pictures={pictureURLs} description={description} />
                        <HomePageComponent2 products={products} />
                        <HomePageComponent3 companies={companies} />
                        {/*<HomePageComponent4 />*/}
                    </>
                )}
                <Footer />
            </HomeWrapper>
        );
    }
}

export default HomePage;
