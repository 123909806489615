import React, { Component } from 'react';
import styled from 'styled-components';
import * as auth from '../../firebase/auth';
import withAuthorization from '../../AuthContext/withAuthorization';

import ReactTable from 'react-table';
import {
    createCompanyLogo,
    createMainPhoto,
    createProduct,
    deleteCompany,
    deleteMainPhoto,
    deleteProduct,
    editProduct,
    getCompanies,
    getDescription,
    getPictures,
    getProducts,
    setDescription,
    setActive,
    updatePhoto,
} from '../../firebase/db';
import 'react-table/react-table.css';
import Modal from 'react-modal';
import WysiwygEditor from '../InputFields/WysiwygEditor';
import LoadingIcon from '../../assets/LoadingIcon';
import { LoadingWrapper } from '../../pages/ProductsPage';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`;

const HeaderWrapper = styled.div`
    display: flex;
    justify-content: space-between;
`;

const ContentWrapper = styled.div``;

const Header = styled.h3``;

const StyledButton = styled.button`
    margin: 16px;
`;

const ItemWrapper = styled.div`
    margin: 32px 16px;
`;

export const ImageWrapper = styled.div`
    position: relative;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    background: #e8e8e8;
    height: 180px;
    width: 270px;
    flex: 0 0 270;
    border-radius: 5px;
    overflow: hidden;
`;

export const TableImageWrapper = styled(ImageWrapper)`
    height: 40px;
    width: 60px;
    margin-right: 0;
    border-radius: 5px;
    overflow: hidden;
`;

const PictureWrapper = styled(ImageWrapper)`
    height: 120px;
    width: 200px;
    border-radius: 5px;
    overflow: hidden;
    margin: 16px;
`;

const Edit = styled.button`
    background: none;
    color: blue;
    border: none;
    padding: 0 !important;
    font: inherit;
    cursor: pointer;
    outline: none;
`;

const Delete = styled.div`
    color: #ec2929;
    cursor: pointer;
    &:hover {
        color: #57646f;
    }
`;

const FormWrapper = styled.div`
    display: flex;
    flex-direction: column;

    > * {
        margin-top: 8px;
    }
`;

const Wrp = styled.div`
    display: flex;
    flex-direction: row;
`;

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '50%',
        height: '50%',
    },
};

class ProductEditor extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            products: [],
            sortedBy: 'index',
            modalIsOpen: false,
            productInModal: {},
            newProduct: false,
            pics: [],
            companies: [],
            frontPageDescription: '',
            loading: true,
        };
    }

    componentDidMount() {
        this._isMounted = true;
        Modal.setAppElement('body');
        this.refreshData();
    }

    refreshData = () => {
        const { sortedBy } = this.state;
        getProducts(sortedBy).then(products => {
            let items = [];
            products.forEach(child => {
                items.push({
                    ...child.val(),
                    key: child.key,
                });
            });
            getPictures().then(pictures => {
                let pics = [];
                pictures.forEach(childPics => {
                    pics.push({ ...childPics.val(), key: childPics.key });
                });
                getCompanies().then(c => {
                    let companies = [];
                    c.forEach(comp => {
                        companies.push({ ...comp.val(), key: comp.key});
                    });
                    getDescription().then(description => {
                        if (this._isMounted) {
                            this.setState({
                                products: items,
                                pics,
                                frontPageDescription: description.val(),
                                loading: false,
                                companies
                            });
                        }
                    });
                });
            });
        });
    };

    componentWillUnmount() {
        this._isMounted = false;
    }

    openModal = prod => {
        if (prod === null) {
            const newProd = { title: '', description: '', price: 0 };
            this.setState({ modalIsOpen: true, newProduct: true, productInModal: newProd });
        } else {
            this.setState({ modalIsOpen: true, newProduct: false, productInModal: prod });
        }
    };

    closeModal = () => {
        this.setState({ modalIsOpen: false });
        this.refreshData();
    };

    onChange = event => {
        const { productInModal } = this.state;
        let newItem = productInModal;
        newItem[event.target.name] = event.target.value;
        this.setState({
            productInModal: newItem,
        });
    };

    onChangeChecked = event => {
        const { productInModal } = this.state;
        let newItem = productInModal;
        newItem.featured = event.target.checked;
        this.setState({
            productInModal: newItem,
        });
    };

    getBase64 = file =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                this.setState({
                    newPhoto: reader.result,
                });
                resolve(
                    reader.result && typeof reader.result === 'string' ? reader.result : undefined
                );
            };
        });

    uuid = () => {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
            let r = (Math.random() * 16) | 0,
                v = c === 'x' ? r : (r & 0x3) | 0x8;
            return v.toString(16);
        });
    };

    handleLogo = (values, key) => {
        this.getBase64(values[0]).then(jsonString => {
            const storageRef = auth.storageRef();
            const locationToImgInFolder = storageRef.child(`/productImages/${key}.png`);
            locationToImgInFolder.putString(jsonString, 'data_url').then(snapshot => {
                snapshot.ref.getDownloadURL().then(URL => {
                    updatePhoto(URL, key).then(() => {
                        this.refreshData();
                    });
                });
            });
        });
    };

    handleFrontPageLogo = values => {
        this.getBase64(values[0]).then(jsonString => {
            const storageRef = auth.storageRef();
            const locationToImgInFolder = storageRef.child(`/pictures/${this.uuid()}.png`);
            locationToImgInFolder.putString(jsonString, 'data_url').then(snapshot => {
                snapshot.ref.getDownloadURL().then(URL => {
                    createMainPhoto(URL).then(() => {
                        this.refreshData();
                    });
                });
            });
        });
    };

    addCompanyLogo = values => {
        this.getBase64(values[0]).then(jsonString => {
            const storageRef = auth.storageRef();
            const locationToImgInFolder = storageRef.child(`/companies/${this.uuid()}.png`);
            locationToImgInFolder.putString(jsonString, 'data_url').then(snapshot => {
                snapshot.ref.getDownloadURL().then(URL => {
                    createCompanyLogo(URL).then(() => {
                        this.refreshData();
                    });
                });
            });
        });
    };

    onChangeHtml = html => {
        const { productInModal } = this.state;
        let newItem = productInModal;
        newItem.description = html;
        this.setState({
            productInModal: newItem,
        });
    };

    onChangeHtmlFrontPage = html => {
        this.setState({
            frontPageDescription: html,
        });
    };

    onSubmit = event => {
        const { productInModal, newProduct } = this.state;
        if (newProduct) {
            createProduct(productInModal).then(() => {
                this.closeModal();
            });
        } else {
            editProduct(productInModal).then(() => {
                this.closeModal();
            });
        }

        event.preventDefault();
    };

    setDescription = () => {
        const { frontPageDescription } = this.state;
        setDescription(frontPageDescription);
    };

    deleteProduct = key => {
        deleteProduct(key).then(() => {
            this.refreshData();
        });
    };

    deleteCompany = key => {
        deleteCompany(key).then(() => {
            this.refreshData();
        });
    }
    deleteMainPhoto = key => {
        deleteMainPhoto(key).then(() => {
            this.refreshData();
        });
    };

    setActive = (key, active) => {
        setActive(key, active).then(() => {
            this.refreshData();
        });
    }

    render() {
        const {
            products,
            productInModal,
            newProduct,
            pics,
            companies,
            frontPageDescription,
            loading,
        } = this.state;
        if (loading) {
            return (
                <LoadingWrapper>
                    <LoadingIcon />
                </LoadingWrapper>
            );
        }
        return (
            <Wrapper>
                <HeaderWrapper>
                    <StyledButton onClick={() => this.openModal(null)}>Create Product</StyledButton>
                    <Header>Product Editor</Header>
                    <StyledButton onClick={() => auth.doSignOut()}>Sign Out</StyledButton>
                </HeaderWrapper>
                <ContentWrapper>
                    <ItemWrapper>
                        <label>Front Page Description:</label>
                        <WysiwygEditor
                            value={frontPageDescription}
                            onChange={this.onChangeHtmlFrontPage}
                        />
                        <button onClick={() => this.setDescription()}>Save</button>
                    </ItemWrapper>
                    <ItemWrapper>
                        <ReactTable
                            data={products}
                            columns={[
                                {
                                    Header: '#',
                                    accessor: 'index',
                                    width: 100,
                                    Cell: props => props.value ? props.value : '',
                                },
                                {
                                    Header: 'Picture',
                                    accessor: 'photoURL',
                                    width: 80,
                                    Cell: ({ original: prod }) => {
                                        return (
                                            <TableImageWrapper>
                                                <input
                                                    name="logo"
                                                    type="file"
                                                    accept="image/*"
                                                    id={`logo${prod.key}`}
                                                    onChange={e =>
                                                        this.handleLogo(e.target.files, prod.key)
                                                    }
                                                    style={{ display: 'none' }}
                                                />
                                                <img
                                                    src={prod.photoURL}
                                                    alt={prod.photoURL}
                                                    width={60}
                                                    height={40}
                                                    onClick={() =>
                                                        document
                                                            .getElementById(`logo${prod.key}`)
                                                            .click()
                                                    }
                                                />
                                            </TableImageWrapper>
                                        );
                                    },
                                },
                                {
                                    Header: 'Title',
                                    accessor: 'title',
                                    Cell: ({ original: prod }) => (
                                        <Edit onClick={() => this.openModal(prod)}>
                                            {prod.title}
                                        </Edit>
                                    ),
                                },
                                {
                                    Header: 'Active',
                                    accessor: 'active',
                                    width: 100,
                                    Cell: ({ original: prod }) => (
                                        <input
                                            type={'checkbox'}
                                            name={'active'}
                                            checked={prod.active}
                                            onChange={() => this.setActive(prod.key, !prod.active)}
                                        />
                                    )
                                },
                                {
                                    Header: 'Featured',
                                    accessor: 'featured',
                                    width: 100,
                                    Cell: props => (props.value ? 'Yes' : ''),
                                },
                                {
                                    Header: 'Company',
                                    accessor: 'company',
                                },
                                {
                                    Header: 'Price',
                                    accessor: 'price',
                                },
                                {
                                    Header: '',
                                    accessor: 'id',
                                    Cell: ({ original: prod }) => (
                                        <Delete onClick={() => this.deleteProduct(prod.key)}>
                                            Delete
                                        </Delete>
                                    ),
                                },
                            ]}
                        />
                        <Modal
                            isOpen={this.state.modalIsOpen}
                            onRequestClose={this.closeModal}
                            style={customStyles}
                            contentLabel=""
                        >
                            <h2>{newProduct ? 'Create Product' : 'Edit product'}</h2>
                            <form onSubmit={this.onSubmit}>
                                <FormWrapper>
                                    <label>Title:</label>
                                    <input
                                        type={'text'}
                                        name={'title'}
                                        value={productInModal.title}
                                        onChange={this.onChange}
                                    />
                                    <label>Short Description:</label>
                                    <input
                                        type={'text'}
                                        name={'shortDescription'}
                                        value={productInModal.shortDescription}
                                        onChange={this.onChange}
                                    />
                                    <label>Description:</label>
                                    <WysiwygEditor
                                        value={productInModal.description}
                                        onChange={this.onChangeHtml}
                                    />
                                    <label>Price:</label>
                                    <input
                                        type={'text'}
                                        name={'price'}
                                        value={productInModal.price}
                                        onChange={this.onChange}
                                    />
                                    <label>Company:</label>
                                    <input
                                        type={'text'}
                                        name={'company'}
                                        value={productInModal.company}
                                        onChange={this.onChange}
                                    />
                                    <label>Index</label>
                                    <input
                                        type={'number'}
                                        name={'index'}
                                        value={productInModal.index}
                                        onChange={this.onChange}
                                    />
                                    <label>Featured product?</label>
                                    <input
                                        type={'checkbox'}
                                        name={'featured'}
                                        checked={productInModal.featured}
                                        onChange={this.onChangeChecked}
                                    />
                                    <button type={'submit'}>Save</button>
                                </FormWrapper>
                            </form>
                        </Modal>
                    </ItemWrapper>
                    <ItemWrapper>
                        <h3>Companies</h3>
                        {companies &&
                            companies.map((c, ind) => (
                                <Wrp key={ind}>
                                    <PictureWrapper>
                                        <img
                                            src={c.photoURL}
                                            alt={c.photoURL}
                                            width={200}
                                            height={120}
                                        />
                                    </PictureWrapper>
                                    <button
                                        style={{ margin: '60px 0', padding: '0 16px' }}
                                        onClick={() => this.deleteCompany(c.key)}
                                    >
                                        Delete
                                    </button>
                                </Wrp>
                            ))}
                        <label>Upload company picture: </label>
                        <input
                            name="companyLogo"
                            type="file"
                            accept="image/*"
                            id={'compPictures'}
                            onChange={e => {
                                this.addCompanyLogo(e.target.files);
                            }}
                            style={{ display: 'none' }}
                        />
                        <button onClick={() => document.getElementById('compPictures').click()}>
                            Upload
                        </button>
                    </ItemWrapper>
                    <ItemWrapper>
                        <h3>Front page pictures</h3>
                        {pics &&
                            pics.map((p, ind) => (
                                <Wrp key={ind}>
                                    <PictureWrapper>
                                        <img
                                            src={p.photoURL}
                                            alt={p.photoURL}
                                            width={200}
                                            height={120}
                                        />
                                    </PictureWrapper>
                                    <button
                                        style={{ margin: '60px 0', padding: '0 16px' }}
                                        onClick={() => this.deleteMainPhoto(p.key)}
                                    >
                                        Delete
                                    </button>
                                </Wrp>
                            ))}
                        <label>Upload picture: </label>
                        <input
                            name="logo"
                            type="file"
                            accept="image/*"
                            id={'pictures'}
                            onChange={e => {
                                this.handleFrontPageLogo(e.target.files);
                            }}
                            style={{ display: 'none' }}
                        />
                        <button onClick={() => document.getElementById('pictures').click()}>
                            Upload
                        </button>
                    </ItemWrapper>
                </ContentWrapper>
            </Wrapper>
        );
    }
}

export default withAuthorization(ProductEditor);
