import React, { useContext } from 'react';
import {AUTHENTICATION} from '../App';
import { Redirect } from 'react-router-dom';
import AuthUserContext from '../AuthContext/AuthUserContext';
import ProductEditor from '../components/AdminComponents/ProductEditor';

const AdminArea = () => {
    const authUser = useContext(AuthUserContext);
    return <>{authUser ? <ProductEditor /> : <Redirect to={AUTHENTICATION} />}</>;
};

export default AdminArea;
