import * as React from 'react';

const FacebookIcon = () => (
    <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <rect width="32" height="32" fill="url(#patternFb)" />
        <defs>
            <pattern id="patternFb" patternContentUnits="objectBoundingBox" width="1" height="1">
                <use xlinkHref="#imageFb" transform="scale(0.03125)" />
            </pattern>
            <image
                id="imageFb"
                width="32"
                height="32"
                xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAMAAABEpIrGAAAAA3NCSVQICAjb4U/gAAAACXBIWXMAAAEVAAABFQEpfgIbAAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAAKVQTFRF////AAD/Rl2LRFWZS1qWR1WOSlqURFqWRlmTRVqVR1eURViTSFeVSVuSR1iSRlqUR1mTRliSSFqSR1mTR1iSR1qTR1mUR1mSRliTRlmTR1iSRlmUR1qTR1iUR1mTSFqTR1mTR1mUR1mTR1mUR1mTR1mTR1mUR1mTR1mTR1iTR1mTSFmTR1iTR1mUR1mTR1mTR1mSR1mTR1qTR1mTR1mTR1mTR1mTC81MmAAAADZ0Uk5TAAELDxESHyIoMDI0NTg9PlNUYGFlb3BzdHiIipefuLnBxcjM1dfY2drb3N3e3+jq6/P19/3+2Jd4mAAAAMRJREFUOMul09cSwiAQBdAVjb0XNEGNhaixF+T/P00x44hkAzN639g9LzBcAMK4kGgEZwSARNKSiACT1jDgdsBB2IEAZHhZTIIgiJNDGtzHeVBpZYEuJGlkgCk4QPMNBhnAU8t2eL1JHBzVPnf+DEywUqAkHaD8M1j6fl8Bz39mhoAaaCkioKKDugt0EFDVwRABc0p7aleglI5O+DUPrnfYu8Dub7B1gTgFhB0IszgbA3CzemsDMLO8BohUvb/qr3+YV/0fuxCrFu+iXGoAAAAASUVORK5CYII="
            />
        </defs>
    </svg>
);

export default FacebookIcon;
