import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import styled, { ThemeProvider } from 'styled-components';
import basicTheme from './themes/basicTheme';
import HomePage from './pages/HomePage';
import ProductsPage from './pages/ProductsPage';
import AuthPage from './pages/AuthPage';
import AdminArea from './pages/AdminArea';
import withAuthentication from './AuthContext/withAuthentication';

export const HOME = '/';
export const AUTHENTICATION = '/auth';
export const PRODUCTS = '/products';
export const ADMIN = '/admin';

const AppWrapper = styled.div`
    height: 100%;
    width: 100%;
    background: white;
    overflow: hidden;
    font-family: 'Muli', sans-serif;
`;

const App = () => (
    <Router>
        <ThemeProvider theme={basicTheme}>
            <AppWrapper className="app">
                <Route exact path={HOME} component={() => <HomePage />} />
                <Route path={AUTHENTICATION} component={() => <AuthPage />} />
                <Route path={PRODUCTS} component={() => <ProductsPage />} />
                <Route path={ADMIN} component={() => <AdminArea />} />
            </AppWrapper>
        </ThemeProvider>
    </Router>
);

export default withAuthentication(App);
