import React, { Component } from 'react';
import styled from 'styled-components';
import ProductCard from './card/ProductCard';

const Wrapper = styled.div`
    height: 568px;
    width: 100%;
    @media (max-width: 1000px) {
        height: auto;
    }
`;

const PageWrapper = styled.div`
    padding: 64px;
`;

const ProductHeader = styled.div`
    font-size: 24px;
    line-height: 24px;
    font-weight: bold;
    text-align: center;
`;

const CardWrap = styled.div`
    padding-top: 56px;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
`;

class HomePageComponent2 extends Component {
    render() {
        const { products } = this.props;
        return (
            <Wrapper>
                <PageWrapper>
                    <ProductHeader>Vinsælar vörur</ProductHeader>
                    <CardWrap>
                        {products.map((p, index) => (
                            <ProductCard key={index} product={p} />
                        ))}
                    </CardWrap>
                </PageWrapper>
            </Wrapper>
        );
    }
}

export default HomePageComponent2;
