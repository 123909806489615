import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
    width: 100%;
    margin: 32px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
`;

const StyledImage = styled.img`
    max-width: 200px;
    object-fit: contain;
    height: 150px;
    margin: 12px;
`;

class HomePageComponent3 extends React.Component {
    render() {
        const { companies } = this.props;
        return (
            <Wrapper>
                {Object.values(companies).map(c => (
                    <StyledImage src={c.photoURL} alt={c.title} key={c.photoURL} />
                ))}
            </Wrapper>
        );
    }
}

export default HomePageComponent3;
