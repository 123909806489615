import * as firebase from 'firebase/app';

import 'firebase/auth';
import 'firebase/database';
import 'firebase/storage';

let prodConfig = {
    appId: 'health-123456',
    apiKey: 'AIzaSyClKuIcjQoz33HJWgzTSW1Ws2zSZzDIXr0',
    authDomain: 'fourhealth-is.web.app',
    databaseURL: 'https://fourhealth-is.firebaseio.com',
    projectId: 'gs://fourhealth-is.appspot.com',
    storageBucket: 'gs://fourhealth-is.appspot.com',
    messagingSenderId: '232272362881',
};

let devConfig = {
    appId: 'health-123456',
    apiKey: 'AIzaSyClKuIcjQoz33HJWgzTSW1Ws2zSZzDIXr0',
    authDomain: 'fourhealth-is.web.app',
    databaseURL: 'https://fourhealth-is.firebaseio.com',
    projectId: 'fourhealth-is',
    storageBucket: 'gs://fourhealth-is.appspot.com',
    messagingSenderId: '232272362881',
};

const config = process.env.NODE_ENV === 'production' ? prodConfig : devConfig;

if (!firebase.apps.length) {
    firebase.initializeApp(config);
}

const db = firebase.database();
const auth = firebase.auth();
const storage = firebase.storage();

export { db, auth, storage };
