import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 600px;
    width: 700px;
    > div {
        margin: 0 auto;
    }
    
    @media(max-width: 700px){
        width: 100%;
        height: 100%;
    }
`;

const Image = styled.img`
    max-width: 100%;
    max-height: 300px;
`;

const ModalProduct = ({ product }) => (
    <Wrapper>
        <div>
            <Image src={product.photoURL} alt={product.photoURL} />
        </div>
        <span>
            <h5>Description</h5>
            <span dangerouslySetInnerHTML={{ __html: product.description }} />
        </span>
    </Wrapper>
);

export default ModalProduct;
