import React, { Component } from 'react';
import styled from 'styled-components';
import { formatLongString, getHtmlString } from '../../utils/StringUtils';
import Modal from '../Modal/Modal';
import ModalProduct from '../../pages/ModalProduct';

const CardWrapper = styled.div`
    padding-left: 16px;
    padding-right: 16px;
    display: flex;
    flex: 0 0 33%;
    max-width: 320px;
    flex-direction: column;
    box-sizing: border-box;
    margin-bottom: 32px;
    max-height: 400px;

    @media (max-width: 1000px) {
        flex: 0 0 50%;
        max-width: 50%;
        margin: 24px auto 16px;
        max-height: 550px;
    }
    @media (max-width: 600px) {
        flex: 0 0 100%;
        max-width: 100%;
        margin: 8px auto 16px;
    }
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: hidden;
    overflow-wrap: break-word;
    position: relative;
    border: 1px solid ${props => props.theme.borderColor};
    border-radius: 12px;
    height: 100%;

    background: #fff;
    transition: 0.4s ease all;

    &:hover {
        box-shadow: 0 4px 8px 0 hsla(0, 0%, 0%, 0.2);
    }
`;

const ImageWrapper = styled.div`
    position: relative;
    overflow: hidden;
    line-height: 0;
    transition: 0.4s ease all;
    height: 0;
    padding-bottom: 70%;

    &:after {
        content: '';
        display: block;
        position: absolute;
        background: rgba(0, 0, 0, 0.3);
        opacity: 0;
        transition: 0.4s ease all;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }
`;

const Image = styled.img`
    position: absolute;
    top: 0;
    left: 0;
    width: 101%;
    height: 101%;
    transform: translateZ(0);
    transition: 0.4s ease all;
`;

const InfoWrap = styled.div`
    display: flex;
    flex-direction: column;
    padding: 16px;
    height: 35%;
`;

const Title = styled.h2`
    margin-top: 0;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 8px;
    transition: 0.4s ease all;
    overflow: hidden;
    height: 30px;
`;

const Description = styled.div`
    height: 110px;
    overflow: hidden;
    line-height: 1.5;
    overflow-wrap: break-word;
    font-weight: 300;
    font-size: 14px;
`;

const Price = styled.div`
    display: flex;
    justify-content: flex-end;
    overflow-wrap: break-word;
    margin-top: 8px;
    font-size: 18px;
    line-height: 24px;
    font-weight: 300;
    height: 0;
`;

class ProductCard extends Component {
    render() {
        const { product } = this.props;
        return (
            <Modal
                title={product.title}
                showCloseAction={true}
                trigger={openModal => (
                    <CardWrapper onClick={() => openModal()}>
                        <Wrapper>
                            <ImageWrapper>
                                <Image src={product.photoURL ? product.photoURL : null} />
                            </ImageWrapper>
                            <InfoWrap>
                                <Title>{formatLongString(product.title, 24, '...')}</Title>
                                <Description>
                                    {formatLongString(
                                        getHtmlString(product.shortDescription),
                                        120,
                                        '...'
                                    )}
                                </Description>
                                <Price>{product.price ? product.price + ' kr.' : ''}</Price>
                            </InfoWrap>
                        </Wrapper>
                    </CardWrapper>
                )}
            >
                {() => <ModalProduct product={product} />}
            </Modal>
        );
    }
}

export default ProductCard;
