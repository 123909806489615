import React, {Component} from 'react';
import styled from 'styled-components';
import {getProducts} from '../firebase/db';
import Header from '../components/Header';
import Footer from '../components/Footer';
import ProductCard from '../components/card/ProductCard';
import {HomeWrapper} from '../themes/basicTheme';
import Select from 'react-select';
import LoadingIcon from '../assets/LoadingIcon';

const ProductHeader = styled.div`
  font-size: 24px;
  line-height: 24px;
  font-weight: bold;
  text-align: center;
  margin-top: 32px;
`;

const CardWrap = styled.div`
  padding-top: 8px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
`;

const FilterWrapper = styled.div`
  display: flex;
  margin: 16px;
  color: ${props => props.theme.mainColor};
  font-size: 14px;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;

  .select {
    width: 200px;
  }
`;

export const LoadingWrapper = styled.div`
  width: 100%;
  height: 300px;
  margin-top: 75px;
  text-align: center;
`;

class ProductsPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            sortedBy: 'index',
            products: [],
            selectedOption: null,
            options: [],
        };
    }

    getData = () => {
        const {sortedBy} = this.state;
        getProducts(sortedBy).then(products => {
            let productArray = [];
            products.forEach(val => {
                productArray.push(val.val());
            });
            let options = [];
            productArray.forEach(item => {
                if (!options.some(e => e.value === item.company) && item.company !== undefined) {
                    options.push({value: item.company, label: item.company});
                }
            });
            this.setState({
                products: productArray,
                options,
                loading: false,
            });
        });
    }

    componentDidMount() {
        this.getData();
    }

    handleChange = selectedOption => {
        this.setState({selectedOption});
    };

    /*    handleChangeSorting = selectedSorting => {
            this.setState({ sortedBy: selectedSorting.value }, () => this.getData());
        }*/

    render() {
        const {products, selectedOption, loading, options} = this.state;
        /*const sortingOptions = [{label: 'Sjálfgefin röð', value: 'index'}];*/
        return (
            <HomeWrapper>
                <Header/>
                <ProductHeader>Vörur</ProductHeader>
                {loading ? (
                    <LoadingWrapper>
                        <LoadingIcon/>
                    </LoadingWrapper>
                ) : (
                    <>
                        <FilterWrapper>
                            {/* <span>Raða eftir: </span>
                            <Select
                                className="select"
                                value={selectedOption}
                                onChange={this.handleChangeSorting}
                                options={sortingOptions}
                                isClearable={true}
                            />*/}
                            <span>Framleiðandi: </span>
                            <Select
                                className="select"
                                value={selectedOption}
                                onChange={this.handleChange}
                                options={options}
                                isClearable={true}
                                placeholder={'Veldu'}
                            />
                        </FilterWrapper>
                        <CardWrap>
                            {products
                                .filter(i => i.active)
                                .filter(i =>
                                    selectedOption ? i.company === selectedOption.value : true
                                )
                                .map((p, index) => (
                                    <ProductCard key={index} product={p}/>
                                ))}
                        </CardWrap>
                    </>
                )}
                <Footer/>
            </HomeWrapper>
        );
    }
}

export default ProductsPage;
