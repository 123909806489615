import React from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import SignInForm from '../components/SignIn/SignInForm';
import styled from 'styled-components';
import { ADMIN } from '../App';
import { getCurrentUser } from '../firebase/auth';

const SignInWrapper = styled.div`
    border-radius: 12px;
    border: 1px solid ${props => props.theme.borderColor};
    height: 170px;
    width: 400px;
    margin: 200px auto;
    padding: 16px;
`;

const AuthPage = ({ history, match }) => {
    if (getCurrentUser() !== null) {
        return <Redirect to={ADMIN} />;
    }
    return (
        <SignInWrapper>
            <h2>Sign In</h2>
            <SignInForm history={history} />
        </SignInWrapper>
    );
};

export default withRouter(AuthPage);
