import styled from "styled-components";

const SPACING_DEFAULT = 8;

const theme = {
    mainColor: '#47B9DD',
    secondaryColor: '#1C82B3',
    bannerBackground: '#2ecc71',
    cardsBackground: '#d4cde4',
    borderColor: '#e5e5e5',
    baseBoxShadow:
        '0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23)',
    spacing: (modifier = 1) => `${SPACING_DEFAULT * modifier}px`,
};

export const HomeWrapper = styled.div`
    height: 100%;
    max-width: 1280px;
    margin: auto;
    
`;

export default theme;
