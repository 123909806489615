import * as React from 'react';

const Login = () => (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect width="24" height="24" fill="url(#pattern2)" />
        <defs>
            <pattern id="pattern2" patternContentUnits="objectBoundingBox" width="1" height="1">
                <use xlinkHref="#image2" transform="scale(0.0416667)" />
            </pattern>
            <image
                id="image2"
                width="24"
                height="24"
                xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAQAAABKfvVzAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAKqNIzIAAAAJcEhZcwAADdcAAA3XAUIom3gAAAAHdElNRQfjBRoSLB3u1YtuAAAB20lEQVQ4y42SsWtTURSHv3Pz2oRkaLBWk5dEKsalBQfRQYUITi7VyclZO7mLf4Dg4ObUWQTj1sXNoViwYCfHmkBJXtokpDStKEnfy3F4vuTZ3Iq/5d5z7vn4nXvuFf6SSusWj7hNFjhkkw/uF9F4hcSD1oI+1wt8ZEN6oPPc5b60eVXoWoG9OX1JXde1ocPSCJqGWSnJQxZ54R5ZAO8ZKdb5Xghingm9ygN+Ft5EGRNtamlusE09Xg5uIDW+crOXngJSlzmg455wSvkTOhwMFqcAmeeQgZyuR2BAn/NTADMEKDYpPjNR4ITL/mxwSctyz1uyADkt4+3P5objKTWz8oRzGLoysBgkWUDpjdZK/T8OskKWDdnDt3Uk4KjLHbPC2+gFql7FS8aLdsyOiceNVLPiVSd3cHW3Pxy3IN41rgut7fy36B8dDeZ2cSeAkWB5PKF2RiosAZl2nR9hblm9IJyoY7lklgyfQK9oNgImsgEOPkPAV8upJSW+OqQAxzY1C2COR0PKwC9zbDmdTl3s6yZp0vo51/8vByhuscUZCh0CEg3hH2oKCYIJ4GlRkp0zy7tIUkvaHLekVVmV10HNG9n+t+AbKbMq78MotHzMU5PXhB2QYNRirfgO4DcfvaiuYd4dTgAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAxOS0wNS0yNlQxNjo0NDoyOSswMjowMJRZDuAAAAAldEVYdGRhdGU6bW9kaWZ5ADIwMTktMDUtMjZUMTY6NDQ6MjkrMDI6MDDlBLZcAAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAAABJRU5ErkJggg=="
            />
        </defs>
    </svg>
);
export default Login;
