import React from 'react';
import { withRouter } from 'react-router-dom';

import AuthUserContext from './AuthUserContext';
import * as routes from '../App';
import { firebase } from '../firebase';

const condition = authUser => !!authUser;

const withAuthorization = Component => {
    class WithAuthorization extends React.Component {
        componentDidMount() {
            firebase.auth.onAuthStateChanged(authUser => {
                if (!condition(authUser)) {
                    this.props.history.push(routes.AUTHENTICATION);
                }
            });
        }

        render() {
            return (
                <AuthUserContext.Consumer>
                    {authUser => (authUser ? <Component {...this.props} /> : null)}
                </AuthUserContext.Consumer>
            );
        }
    }

    return withRouter(WithAuthorization);
};

export default withAuthorization;
