import * as React from 'react';

const InstagramIcon = () => (
    <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <rect width="32" height="32" fill="url(#patternIg)" />
        <defs>
            <pattern id="patternIg" patternContentUnits="objectBoundingBox" width="1" height="1">
                <use xlinkHref="#imageIg" transform="scale(0.03125)" />
            </pattern>
            <image
                id="imageIg"
                width="32"
                height="32"
                xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAMAAABEpIrGAAAAA3NCSVQICAjb4U/gAAAACXBIWXMAAADOAAAAzgGU/pOgAAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAAftQTFRF/////wAA/wD/gACA/4CAqgBVqgCqqlVVnyCAsxqZvxWAvytqv0BVgCazjBqm2YxAdSC/lSCf1WpK35VArxiHryCHx0hQx1BQih2omR2gzGZJ03VCnxyYmSCfnxyZwShsxi1mzFtKhCCyshuEuxuAvxd2xBtyxCBtxClpxC5kxDJkxDdbxDtbxEBXyElS1oBEqh6IhiCuqhyKuhh9wiBtwjFhxkFVxk1N131FdyO7iiGr1HVE3JA+cSTBmB+dzmdI4Jg+pR6RpRyQvxp4xDtay1VMhSGvphyRyVRL135ErR2Jvxp4xTxaoB+XcyPCnx6WzF1J35g+lx+chSGwnh6WzF1J139DrRuJfyK2hCGx139D2odCuhp+whxzxTdexkNVrByKuhl9xkFVx01NiyCqkiCj0m5G1HZEphyQwhxzxTZdylVLciXCpR2QylVK35c+mB+ddyO8kh+jph2Q0m5G3I8/sxuEx0dRhSGwmB+dpR2QwyJuxDJiylVLz2ZI139DciTCrByKx01N35g+mB+dshuEwydqxCxmx0dRciTCeCO8fyK2hSGwiyCqkh+jmB+dnx6Xph2QrByKshuEuRp+vxl4whxzwyJuwydqxCxmxDJixTddxTxZxkJVx0dRx01NylVLzV1Jz2ZI0m5G1HZE139D2odB3I8/35g+TEZlRwAAAIl0Uk5TAAEBAgIDAwMICgwMDBQUFBgYGBggICAgIyMjIyUoLS0tLTg4ODg4ODg4ODg4ODg4PD8/Pz8/Pz8/Xl5eXmNjY2NmbGxsbG1tbW1zd3eOl5eXl5uhoaGhp7Ozs7O0tLS0v7+/v8LCwsLFxcXFysrKyt7v7+/v7/Dw9fX19fX19fX4+Pj4/P39/f1iCsudAAACJElEQVQ4T22TT08TURRHzzMPTY1VZxig1sxgYloyTYiEdlFQi3Fph4X6CfSDARvjztUMGyRxUSyRQKJNnNDGGAaEUsqMWJOm/quLaaG0/Hb3npP77kvekwDcnFQMLcJZmnUvKH0HkAAz2Vucz1XDILNeDIVoPs1FiT8bdxpIsNJQrzSOTnrgjZFoQiMjXiOZzYBrVwcGxCyTzM57qWQF7uLffnzpWnXxpUnWlZM6NXuA68+1rTe2NqpPSkVQOTgFl2NUfwFTd8h92KuMoUhD0OiOfZRUxzj0y+/+/RR4xzQEhtQERyEfyU8DxGIpwylGottNjgSaVCG8n/ZCB35wHdKjC8sAJwJVChAAYt6AddfFNLMY80ttQAg4FR5nYNkBNjeDJ2S81X4hKSg64TKOOkOyXxhSBS6duLOoQ7/PC3Ed/0wIVD2+c14QIjwpTKfqFQ52jeHURoenhvEO+oSWP47ZFUyB3+oTKE+T820ArByU6RdWjfs8HXY/kzJzsLY6ILTtkQnm5mqMAtt2+1TY0wmfc23JegBjAAW7BkBEsCvrBkq42uGCl1Tv8sUvv22HHUVQl16aaFjSXlm5Eme/RTdRgScDgRnf77ZaXzlL3BQEsvTpXsJa+MNgpJXgY0n6xSlywv42wG9bD6HoS9bG8+RibiNo9tCIEjWT4KwhwRF5JiYGJgCOAxJOXu3MTF+At4oFOr+74G4ohpbogZW6F5SOAf4DFVKv+uC+tE8AAAAASUVORK5CYII="
            />
        </defs>
    </svg>
);

export default InstagramIcon;
