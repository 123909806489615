import React from 'react';
import styled from 'styled-components';
import Logo from '../assets/Logo';
import { Link } from 'react-router-dom';
import Login from '../assets/Login';

const HeaderWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    height: 80px;
`;

const LogoWrapper = styled(Link)`
    flex: 1;
    background-color: white;
    margin: auto;
`;

const SecondWrapper = styled.div`
    flex: 1;
    background-color: ${props => props.theme.mainColor};
    @media(max-width: 800px){
        display: none;
    }
`;

const ContentWrapper = styled.div`
    flex: 0 0 auto;
    display: flex;
    justify-content: flex-end;
`;

const LinkWrapper = styled(Link)`
    height: 24px;
    margin: 24px 32px;
`;

const Header = () => (
    <HeaderWrapper>
        <LogoWrapper to={'/'}>
            <Logo width={200} height={60} />
        </LogoWrapper>
        <SecondWrapper>
            <ContentWrapper>
                <LinkWrapper to={'/auth'}>
                    <Login />
                </LinkWrapper>
            </ContentWrapper>
        </SecondWrapper>
    </HeaderWrapper>
);

export default Header;
