import React from 'react';
import ReactModal from 'react-modal';
import styled from 'styled-components';
import CloseIcon from '../../assets/CloseIcon';

const ModalHeader = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    margin-bottom: 8px;
    position: sticky;
    background: rgba(255, 255, 255, 0.8);
    padding: calc(8px * 1.5) 0 8px 0;
    top: 0;
    z-index: 1;

    @keyframes fadeInAnimationOverlay {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }
`;

const TitleWrapper = styled.div`
    display: flex;
    flex-direction: column;
    h3 {
        flex: 1;
        margin: 0;
        font-weight: 400;
        color: black;
    }

    h5 {
        flex: 1;
        margin: 8px 0 0;
        font-weight: 400;
        color: gray;
    }
`;

const ModalActions = styled.div`
    display: flex;
    flex-direction: row;

    > div {
        &:first-child {
            margin-left: 8px;
        }
        margin: 0;
    }
`;

const StyledModal = styled(ReactModal)`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    align-self: center;
    justify-content: center;
    background-color: rgba(34,45,67,0.65);
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
    z-index: 2147483628;
    animation: fadeInAnimationOverlay 0.2s ease-in forwards;
    border: 1px solid gray;

    > div {
        width: 100%;
        justify-content: center;
        display: flex;
    }
`;

const StyledModalContainer = styled.div`
    background-color: #fff;
    border-radius: 5px;
    max-width: 90vw;
    max-height: 90vh;
    overflow-y: auto;
    min-width: 400px;
    padding: 0 32px 32px;
    z-index: 2147483630;
    -webkit-overflow-scrolling: touch;
    position: relative;
    animation: fadeInUpContainer 500ms cubic-bezier(0.32, 1, 0.32, 1) forwards;
    overflow-x: hidden;

    @keyframes fadeInUpContainer {
        from {
            transform: translate3d(0, -80px, 0);
        }

        to {
            transform: translate3d(0, 0, 0);
        }
    }

    @media screen and (max-width: 800px) {
        min-width: auto;
    }
`;

const ClickableIcon = styled.div`
    height: 32px;
    width: 36px;
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 8px;
    border-radius: 5px;

    > span {
        margin: 0;
        display: block;
        height: 4px;
        width: 4px;
        border-radius: 50%;
        background: #1d57c7;

        &:not(:last-child) {
            margin-right: 3px;
        }
    }

    &:hover {
        background: gray;
    }
`;

/**
 * Modal Component
 */
class Modal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            modalIsOpen: Boolean(props.modalIsOpen),
        };
    }

    openModal = () => {
        this.setState({ modalIsOpen: true });
    };

    unControlledCloseModal = () => {
        this.setState({ modalIsOpen: false });
    };

    closeModal = () => {
        const { onRequestClose } = this.props;
        if (onRequestClose !== undefined) {
            onRequestClose();
        } else {
            this.unControlledCloseModal();
        }
    };

    render() {
        const { title, subTitle, additionalActions, onAfterOpen, showCloseAction } = this.props;
        return (
            <>
                {this.props.trigger && this.props.trigger(this.openModal)}
                <StyledModal
                    onAfterOpen={onAfterOpen}
                    isOpen={
                        this.props.modalIsOpen !== undefined
                            ? this.props.modalIsOpen
                            : this.state.modalIsOpen
                    }
                    onRequestClose={this.closeModal}
                    style={{
                        overlay: {
                            background: 'rgba(0,0,0,0)',
                            zIndex: 2147483629,
                        },
                    }}
                >
                    <div>
                        <StyledModalContainer
                            isOpen={this.state.modalIsOpen}
                            style={this.props.style}
                            size={this.props.size}
                        >
                            <ModalHeader>
                                <TitleWrapper>
                                    <h3>{title}</h3>
                                    {subTitle && <h5>{subTitle}</h5>}
                                </TitleWrapper>
                                <ModalActions>
                                    {additionalActions}
                                    {showCloseAction && (
                                        <ClickableIcon
                                            onClick={this.closeModal}
                                            data-testid={'close-modal-button'}
                                        >
                                            <CloseIcon />
                                        </ClickableIcon>
                                    )}
                                </ModalActions>
                            </ModalHeader>

                            {typeof this.props.children === 'function'
                                ? this.props.children({
                                      closeModal: this.closeModal,
                                  })
                                : this.props.children}
                        </StyledModalContainer>
                    </div>
                </StyledModal>
            </>
        );
    }
}

export default Modal;
