import { db } from './firebase';

export const getProducts = sortedBy => {
    return db.ref('products').orderByChild(sortedBy).once('value');
};

export const getFeaturedProducts = () => {
    return db.ref('products').orderByChild('featured').equalTo(true).once('value');
};

export const createProduct = newProduct =>
    db.ref('products').push({
        title: newProduct.title,
        shortDescription: newProduct.shortDescription ? newProduct.shortDescription : '',
        description: newProduct.description,
        price: newProduct.price,
        company: newProduct.company ? newProduct.company : '',
        featured: newProduct.featured ? newProduct.featured : false,
        active: true
    });

export const updatePhoto = (photoUrl, id) => db.ref(`products/${id}/photoURL`).set(photoUrl);

export const createMainPhoto = photoUrl =>
    db.ref('pictures/').push({
        photoURL: photoUrl,
    });

export const createCompanyLogo = photoUrl => db.ref(`company/`).push({
    photoURL: photoUrl
});

export const getPictures = () => db.ref('pictures').once('value');

export const getDescription = () => db.ref('description').once('value');

export const setDescription = description => db.ref('description').set(description);

export const getCompanies = () => {
    return db.ref('company').once('value');
};

export const editProduct = newProduct => {
    return db.ref(`products/${newProduct.key}`).update({
        title: newProduct.title,
        shortDescription: newProduct.shortDescription ? newProduct.shortDescription : '',
        description: newProduct.description,
        price: newProduct.price,
        company: newProduct.company,
        featured: newProduct.featured,
        index: parseInt(newProduct.index)
    });
};

export const deleteProduct = productId => db.ref(`products/${productId}/`).remove();

export const deleteMainPhoto = key => db.ref(`pictures/${key}`).remove();

export const deleteCompany = key => db.ref(`company/${key}`).remove();

export const setActive = (key, active) => db.ref(`products/${key}/active`).set(active);
